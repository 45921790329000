import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAnonymousGuard } from './core/guard/is-anonymous.guard';
import { IsAuthenticatedGuard } from './core/guard/is-authenticated.guard';

const routes: Routes = [
  {
    path: 'cards-management',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/cards-management/cards-management.module')
        .then((m) => m.CardsManagementModule)
        .catch((err) => console.error('Cards Management module Error', err)),
  },
  {
    path: 'whitelist',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/whitelist/whitelist.module')
        .then((m) => m.WhitelistModule)
        .catch((err) => console.error('Whitelist module Error', err)),
  },
  {
    path: 'customreports2',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/custom-reports2/custom-reports2.module')
        .then((m) => m.CustomReport2Module)
        .catch((err) => console.error('Reuse Center module Error', err)),
  },
  {
    path: 'customreports',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/custom-reports/custom-reports.module')
        .then((m) => m.CustomReportModule)
        .catch((err) => console.error('Reuse Center module Error', err)),
  },
  {
    path: 'tag-map',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/tags-map/tags-map.module')
        .then((m) => m.TagsMapModule)
        .catch((err) => console.error('Tags Map module Error', err)),
  },
  {
    path: 'cdri',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/reuse-center/reuse-center.module')
        .then((m) => m.ReuseCenterModule)
        .catch((err) => console.error('Reuse Center module Error', err)),
  },
  {
    path: 'kiosks',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/kiosk/kiosk.module')
        .then((m) => m.KioskModule)
        .catch((err) => console.error('Kisok module Error', err)),
  },
  {
    path: 'operators',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/operators/operators.module')
        .then((m) => m.OperatorsModule)
        .catch((err) => console.error('Operators module Error', err)),
  },
  {
    path: 'eco-point',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/eco-points/eco-point.module')
        .then((m) => m.EcoPointModule)
        .catch((err) => console.error('Eco Point module Error', err)),
  },
  {
    path: 'permission-groups',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/group-permission/group-permission.module')
        .then((m) => m.GroupPermissionModule)
        .catch((err) => console.error('Group Permission module Error', err)),
  },
  {
    path: 'reuse-docs',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/reuse-center-docs/reuse-center-docs.module')
        .then((m) => m.ReuseCenterDocsModule)
        .catch((err) => console.error('Reuse Center Docs module Error', err)),
  },
  {
    path: 'delivery-codes',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/delivery-codes/delivery-codes.module')
        .then((m) => m.DeliveryCodeModule)
        .catch((err) => console.error('Delivery Code Module Event module Error', err)),
  },
  {
    path: 'pickup',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/pickups/pickup.module')
        .then((m) => m.PickupModule)
        .catch((err) => console.error('Pickup Module Event module Error', err)),
  },
  {
    path: 'delivery-over-time',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/delivery-over-time/delivery-over-time.module')
        .then((m) => m.DeliveryOverLimitModule)
        .catch((err) => console.error('Delivery Over time Module Event module Error', err)),
  },
  {
    path: 'delivery',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/delivery/delivery.module')
        .then((m) => m.DeliveryModule)
        .catch((err) => console.error('Delivery Module Event module Error', err)),
  },
  {
    path: 'sweeper-reports',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/report-sweeper/report-sweeper.module')
        .then((m) => m.ReportSweeperModule)
        .catch((err) => console.error('Sweeper Report Module Event module Error', err)),
  },
  {
    path: 'conferral-over-limits',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/conferral-over-limits/conferral-over-limits.module')
        .then((m) => m.ConferralOverLimitsModule)
        .catch((err) => console.error('Conferral Over LimitsModule Event module Error', err)),
  },
  {
    path: 'conferrals',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/conferrals/conferrals.module')
        .then((m) => m.ConferralsModule)
        .catch((err) => console.error('Conferrals Module Event module Error', err)),
  },
  {
    path: 'customer_contract',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/contract-utilities/contract-utilities.module')
        .then((m) => m.ContractUtilityModule)
        .catch((err) => console.error('Contract utilities Event module Error', err)),
  },
  {
    path: 'device-event',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/device-event/device-event.module')
        .then((m) => m.DeviceEventModule)
        .catch((err) => console.error('Device Event module Error', err)),
  },
  {
    path: 'service-order',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/service-order/service-order.module')
        .then((m) => m.ServiceOrderModule)
        .catch((err) => console.error('Service Order module Error', err)),
  },
  {
    path: 'chassis-state',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/chassis-state/chassis-state.module')
        .then((m) => m.ChassisStateModule)
        .catch((err) => console.error('Service Order module Error', err)),
  },
  {
    path: 'telemetries',
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/telemetry/telemetry.module')
        .then((m) => m.TelemetryModule)
        .catch((err) => console.error('Telemetry module Error', err)),
  },
  {
    redirectTo: 'login',
    pathMatch: 'full',
    path: '',
  },
  {
    path: 'qr-codes',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/vehicle-qr-code/vehicle-qr-code.module')
        .then((m) => m.VehicleQRcodeModule)
        .catch((err) => console.error('Vehicle QR code module Error', err)),
  },
  {
    path: 'drivers-job-report',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/drivers-job-report/drivers-job-report.module')
        .then((m) => m.DriversJobReportModule)
        .catch((err) => console.error('Drivers Job Report module code module Error', err)),
  },
  {
    path: 'grafana-reports',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/grafana-reports/garafana-reports.module')
        .then((m) => m.GrafanaReportModule)
        .catch((err) => console.error('Grafana Report module code module Error', err)),
  },
  {
    path: 'user',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/user/user.module')
        .then((m) => m.UserModule)
        .catch((err) => console.error('User module Error', err)),
  },
  {
    path: 'group',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/portal-group/portal-group.module')
        .then((m) => m.PortalGroupModule)
        .catch((err) => console.error('Portal group module Error', err)),
  },
  {
    path: 'device',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/device/device.module')
        .then((m) => m.DeviceModule)
        .catch((err) => console.error('Device module Error', err)),
  },
  {
    path: 'vehicle',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/vehicle/vehicle.module')
        .then((m) => m.VehicleModule)
        .catch((err) => console.error('Vehicle module Error', err)),
  },
  {
    path: 'device-collector',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/device-collector/device-collector.module')
        .then((m) => m.DeviceCollectorModule)
        .catch((err) => console.error('Device Collector module Error', err)),
  },
  {
    path: 'login',
    canLoad: [IsAnonymousGuard],
    loadChildren: () =>
      import('./features/login/login.module')
        .then((m) => m.LoginModule)
        .catch((err) => console.error('Login module Error', err)),
  },
  {
    path: 'map',
    canLoad: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./features/map/map.module')
        .then((m) => m.MapModule)
        .catch((err) => console.error('Map module Error', err)),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
